export const mainMenuItems = [
  {
    en: {
      path: '/en/',
      title: 'Home',
    },
    hu: {
      path: '/hu/',
      title: 'Home',
    },
  },
  {
    en: {
      path: '/en/#about-us',
      title: 'About us',
    },
    hu: {
      path: '/hu/#rolunk',
      title: 'Rólunk',
    },
  },
  {
    en: {
      path: '/en/our-wines',
      title: 'Our wines',
    },
    hu: {
      path: '/hu/boraink',
      title: 'Boraink',
    },
  },
  {
    en: {
      path: '/en/gallery',
      title: 'Gallery',
    },
    hu: {
      path: '/hu/galeria',
      title: 'Galéria',
    },
  },
  {
    en: {
      path: '/en/wine-safe-deposit-vault',
      title: 'Wine safe-deposit vault',
    },
    hu: {
      path: '/hu/bortrezor',
      title: 'Bortrezor',
    },
  },
  {
    en: {
      path: '/en/events',
      title: 'Events',
    },
    hu: {
      path: '/hu/rendezvenyek',
      title: 'Rendezvények',
    },
  },
  {
    // en: {
    //   path: '/en/showroom-budapest',
    //   title: 'Showroom Budapest',
    // },
    hu: {
      path: '/hu/szepasszony-volgy',
      title: 'WINE BAR&SHOP – SZÉPASSZONY-VÖLGY',
    },
  },
  {
    en: {
      path: '/en/showroom-budapest',
      title: 'Showroom Budapest',
    },
    hu: {
      path: '/hu/bemutatoterem-budapest',
      title: 'Bemutatóterem Budapest',
    },
  },
  {
    en: {
      path: '/en/#contact',
      title: 'Contact',
    },
    hu: {
      path: '/hu/#kapcsolat',
      title: 'Kapcsolat',
    },
  },
];
