import React from 'react';
import { Link /* useStaticQuery */ } from 'gatsby';
// import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Scroll from '../components/Scroll';
import { getOtherLocale } from '../utils/getOtherLocale';
import { getCurrentLocale } from '../utils/getCurrentLocale';
import { getCurrentPath } from '../utils/getCurrentPath';
import { mainMenuItems } from '../data/paths';

export default function Nav({ onMenuToggle = () => {} }) {
  // const data = useStaticQuery(graphql`
  //   query Flags {
  //     hungarian: file(relativePath: { eq: "images/polish.png" }) {
  //       childImageSharp {
  //         gatsbyImageData(
  //           width: 18
  //           height: 18
  //           placeholder: BLURRED
  //           formats: [AUTO, WEBP, AVIF]
  //         )
  //       }
  //     }
  //     english: file(relativePath: { eq: "images/english.png" }) {
  //       childImageSharp {
  //         gatsbyImageData(
  //           width: 18
  //           height: 18
  //           placeholder: BLURRED
  //           formats: [AUTO, WEBP, AVIF]
  //         )
  //       }
  //     }
  //   }
  // `)

  // const hungarian = getImage(data.hungarian)
  // const english = getImage(data.english)
  const locale = getCurrentLocale();
  const currentUrl = getCurrentPath();

  const getCurrentMenuItem = () => {
    let result = mainMenuItems.find(
      (menuItem) =>
        currentUrl.endsWith(menuItem?.en?.path) ||
        currentUrl.endsWith(menuItem?.hu?.path) ||
        currentUrl.endsWith(menuItem?.en?.path + '/') ||
        currentUrl.endsWith(menuItem?.hu?.path + '/')
    );

    if (!result)
      result = mainMenuItems.find((menuItem) => menuItem.en.path === `/en/`);
    return result;
  };

  return (
    <nav id="nav">
      <ul>
        <li className="special">
          <a
            href="#menu"
            onClick={(e) => {
              e.preventDefault();
              onMenuToggle();
            }}
            className="menuToggle"
          >
            <span>{locale === 'en' ? 'Menu' : 'Menü'}</span>
          </a>
          <div id="menu">
            <ul>
              {getCurrentMenuItem()[getOtherLocale()] ? (
                <li>
                  <a href={getCurrentMenuItem()[getOtherLocale()]?.path}>
                    {
                      locale === 'en' ? (
                        <img
                          className="language-selector"
                          src="/hungarian.png"
                        />
                      ) : (
                        <img className="language-selector" src="/english.png" />
                      )
                      // locale === "en"
                      //   ? <GatsbyImage image={hungarian} alt={"Hungarian"}/>
                      //   : <GatsbyImage image={english} alt={"Angol"}/>
                    }
                  </a>
                </li>
              ) : (
                <></>
              )}
              {mainMenuItems.map((menuItem) =>
                menuItem.hu.title === 'Kapcsolat' ? (
                  <Scroll type="id" element="kapcsolat">
                    <li>
                      <Link to={menuItem[locale].path}>
                        {menuItem[locale].title}
                      </Link>
                    </li>
                  </Scroll>
                ) : menuItem[locale] ? (
                  <li>
                    <Link
                      to={menuItem[locale].path}
                      style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                    >
                      {menuItem[locale].title}
                    </Link>
                  </li>
                ) : (
                  <></>
                )
              )}
            </ul>
            <a
              className="close"
              onClick={(e) => {
                e.preventDefault();
                onMenuToggle();
              }}
              href="#menu"
            >
              {''}
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
}
