export const contact = {
  hu: {
    id: 'kapcsolat',
    contact: 'Elérhetőségeink',
    company: 'Juhászvin Kft.',
    address: '3300 Eger, Kőlyuki Pincesor 35.',
    secretary: 'Titkárság, Értékesítés: +36 70 365 8239, +36 20 960 4099',
    tasting:
      'Borkóstolásra Bejelentkezés: borkostolo@juhaszvin.hu',
    cellar: 'Pincészet: +36 20 215 1608',
    email: 'Email: info@juhaszvin.hu',
    storage: 'Palackozó és Raktár',
    storageAddress: '3394 Egerszalók, Posta u. 2.',
    sales: 'Kereskedelem',
    sales1: 'Kalán Sándor  +36 30 958 3272  Email: kalan.sandor@juhaszvin.hu',
    sales2:
      'Vályi Katalin   +36 30 732 3287  Email: valyi.katalin@juhaszvin.hu',
    webshop: 'Budapesti Bornagyker és WebShop',
    webshopAddress:
      'Gali Tamás    +36 30 316 1751 Email: juhaszvin@jackandwine.hu  Web: www.jackandwine.hu/webshop',
  },
  en: {
    id: 'contact',
    contact: 'Contact',
    company: 'Juhászvin Kft.',
    address: '3300 Eger, Kőlyuki Pincesor 35.',
    secretary: 'Secretary, Retail: +36 70 365 8239, +36 20 960 4099',
    tasting: 'Wine tasting: borkostolo@juhaszvin.hu',
    cellar: 'Cellar: +36 20 215 1608',
    email: 'Email: info@juhaszvin.hu',
    storage: 'Bottler and Warehouse',
    storageAddress: '3394 Egerszalók, Posta str. 2.',
    sales: 'Sales',
    sales1: 'Kalán Sándor  +36 30 958 3272  Email: kalan.sandor@juhaszvin.hu',
    sales2:
      'Vályi Katalin   +36 30 732 3287  Email: valyi.katalin@juhaszvin.hu',
    webshop: 'Budapest Wine Wholesale and WebShop',
    webshopAddress:
      'Gali Tamás    +36 30 316 1751 Email: juhaszvin@jackandwine.hu  Web: www.jackandwine.hu/webshop',
  },
};
