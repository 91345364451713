module.exports = {
  manifestName: 'Juhász',
  manifestShortName: 'Juhász', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#B99B45',
  manifestThemeColor: '#B99B45',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-spectral/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Juhász testvérek',
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/juhasztestverek',
    },
    {
      style: 'brands',
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/juhasztestverekpinceszete/',
    },
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:info@juhaszvin.hu',
    },
  ],
};
