import React, { useState, useEffect } from 'react';
import config from '../../config';
import Modal from 'react-modal';
import { contact } from '../data/contact';
import { getCurrentLocale } from '../utils/getCurrentLocale';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',

    borderRadius: '15px',
  },
};

export default function Footer() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const locale = getCurrentLocale();

  useEffect(() => {
    setIsModalOpen(localStorage.getItem('is18plus') !== 'true');
  }, []);

  const accept = () => {
    setIsModalOpen(false);
    localStorage.setItem('is18plus', true);
  };

  const decline = () => {
    window.location.replace('https://google.com');
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        style={modalStyles}
        contentLabel="Example Modal"
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              width: '52px',
              lineHeight: '44px',
              borderRadius: '50%',
              textAlign: 'center',
              border: '4px solid #1D2329',
              color: '#1D2329',
              fontWeight: 600,
              marginBottom: '15px',
            }}
          >
            18+
          </div>
          <h5 style={{ color: '#1D2329' }}>Elmúltál már 18 éves?</h5>
          <h5 style={{ color: '#1D2329' }}>Are you over the age of 18?</h5>
          <div>
            <button
              style={{ margin: '8px' }}
              className="button primary"
              onClick={accept}
            >
              igen / yes
            </button>
            <button
              style={{
                margin: '8px',
                color: 'white',
                background: '#1D2329',
                boxShadow: 'none',
              }}
              className="button"
              onClick={decline}
            >
              nem / no
            </button>
          </div>
        </div>
      </Modal>

      {/* <section id="cta" className="wrapper style4">
        <div className="inner">
          <header>
            <h2>Iratkozz fel hírlevelünkre!</h2>
            <p>
              Értesülj pincészetünk híreiről első kézből
            </p>
          </header>
          <ul className="actions stacked">
            <li>
              <a href="/#" className="button fit primary">
                Feliratkozás
              </a>
            </li>
          </ul>
        </div>
      </section> */}

      <section
        id="cta"
        className="wrapper style2"
        style={{
          position: 'relative',
        }}
      >
        <div className="inner column infog">
          <h2>forgóeszköz finonszírozás</h2>
          <p>
            <b>Project azonosító száma:</b> GINOP-8.3.5-18/B
            <br style={{ display: 'block' }} />
            <b>Kedvezményezett neve:</b> JUHÁSZVIN Termelő, Kereskedelmi és
            Szolgáltató Kft.
            <br style={{ display: 'block' }} />
            <b>Projekt címe:</b> Mikro kis és középvállalkozások technologiai
            korszerűsítése
            <br style={{ display: 'block' }} />
            <b>Kölcsön összege:</b> 149.000.000 Ft
          </p>

          <p>
            A vállalkozás a gazdasági helyzet, az ukrán-orosz háború
            következtében kialakult finanszírozási és energetikai válság miatt
            támogatási igényt nyújtott be a GINOP-8.3.5-18/B Hitelcél a Mikro-,
            kis- és középvállalkozások technológiai korszerűsítése célú
            Hitelprogram keretében. A projekt megvalósítása során igényelt
            finanszírozás a jelenlegi kiszámíthatatlan gazdasági helyzet miatt
            vált szükségessé, hogy a Cég biztosítani tudja likviditását, a
            készleteinek finanszírozása pedig zökkenőmentes legyen.
          </p>
        </div>
      </section>

      <div id={contact[locale].id}>
        <section id="cta" className="wrapper style1">
          <div className="inner column">
            <h2>{contact[locale].contact}</h2>
            <p>
              {contact[locale].company}
              <br style={{ display: 'block' }} />
              {contact[locale].address}
              <br style={{ display: 'block' }} />
              {contact[locale].cellar}
              <br style={{ display: 'block' }} />
              {contact[locale].tasting}
              <br style={{ display: 'block' }} />
              {contact[locale].storage}
              <br style={{ display: 'block' }} />
              {contact[locale].storageAddress}
            </p>
            <p>
              {contact[locale].secretary}
              <br style={{ display: 'block' }} />
              {contact[locale].email}
            </p>
            <p>
              {contact[locale].sales}
              <br style={{ display: 'block' }} />
              {contact[locale].sales1}
              <br style={{ display: 'block' }} />
              {contact[locale].sales2}
            </p>
            <p>
              {contact[locale].webshop}
              <br style={{ display: 'block' }} />
              {contact[locale].webshopAddress}
            </p>
          </div>
        </section>
      </div>

      <footer id="footer">
        <ul className="icons">
          {config.socialLinks.map((social) => {
            const { style, icon, name, url } = social;
            return (
              <li key={url}>
                <a
                  href={url}
                  target="__blank"
                  className={`icon ${style} ${icon}`}
                >
                  <span className="label">{name}</span>
                </a>
              </li>
            );
          })}
        </ul>
        <ul className="copyright">
          <li>&copy; {contact[locale].company}</li>
          {/* <li>
            Design: <a href="http://html5up.net">HTML5 UP</a>
          </li> */}
        </ul>
      </footer>
    </>
  );
}
