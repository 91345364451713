import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { siteTitle } from '../data/site_title';
import 'lightgallery.js/dist/css/lightgallery.css';
import '../assets/sass/main.scss';
import Footer from './Footer';
import SideBar from './Sidebar';
import { LightgalleryProvider } from 'react-lightgallery';
import { getCurrentLocale } from '../utils/getCurrentLocale';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreloaded: true,
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { children, fullMenu, page } = this.props;
    const { isPreloaded } = this.state;
    const locale = getCurrentLocale();

    return (
      <>
        <Helmet
          title={siteTitle[locale]}
          meta={[
            {
              name: 'description',
              content: 'Juhász testvérek • Tradícionális pincészet',
            },
            {
              name: 'keywords',
              content:
                'bor, borászat, juhász, juhász-testvérek, egerszalók, borpincészet',
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <LightgalleryProvider>
          <div
            className={
              isPreloaded ? 'landing main-body is-preload' : 'landing main-body'
            }
          >
            <div id="page-wrapper">
              <div className={page ?? ''}>
                <SideBar fullMenu={fullMenu} />
                {children}
                <Footer />
              </div>
            </div>
          </div>
        </LightgalleryProvider>
      </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.string,
};

export default Layout;
